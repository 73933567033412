/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  SimpleGrid,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
  useToast,
  WrapItem,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaRegTrashAlt } from 'react-icons/fa'
import { FiMoreVertical, FiPaperclip } from 'react-icons/fi'
import { IoCheckmark, IoCloseOutline } from 'react-icons/io5'
import { RiEditLine } from 'react-icons/ri'
import { Link, useParams } from 'react-router-dom'
import { Ability } from '../../../../../components/ability'
import { PreviewFiles } from '../../../../../components/DropzoneImageUpload/PreviewFiles'
import { toastError } from '../../../../../config/error/toastError'
import { Layout } from '../../../../../layout'
import { getStatus } from '../../../../../services/endpoints/freights/getFreights'
import { useGetLoadingRequest } from '../../../../../services/endpoints/freights/loading-request/GetLoadingRequest'
import {
  LoadingRequestAttachmentTypeEnum,
  LoadingRequestStatusEnum,
} from '../../../../../services/types/EnumTypes'
import { formatAccountType } from '../../../../../services/utils/formatAccountType'
import formatCPFAndCNPJ from '../../../../../services/utils/formatCPFAndCNPJ'
import { getLoadingRequestColorByStatus } from '../../../../../services/utils/getLoadingRequestColorByStatus'
import { loadingRequestStatusLegend } from '../../../../../services/utils/loadingRequestStatusLegend'
import { ListFreightContract } from '../../../show/cards/list-freight-contracts'
import { DeleteLoadingRequestModal } from '../modals/DeleteLoadingRequestModal'
import { HandleLoadingRequestStatusModal } from '../modals/HandleLoadingRequestStatusModal'
import { UpdateLoadingRequestModal } from '../modals/UpdateLoadingRequestModal'
import { UploadLoadingRequestReceiptsModal } from '../modals/UploadLoadingRequestReceiptsModal'

type RequestParams = {
  id: string
}

type AnalysisStatusType = 'approved' | 'rejected' | 'request_review' | 'finish'

type SplitAttachmentsType = {
  genericAttachments: string[]
  receiptAttachments: string[]
}

export function ShowLoadingRequest(): JSX.Element {
  const [splitAttachments, setSplitAttachments] = useState<SplitAttachmentsType>()
  const toast = useToast()
  const { id } = useParams<RequestParams>()
  const bg = useColorModeValue('white', 'gray.800')
  const tabs = ['Gerais:', 'Recibos:']

  const [analysisStatus, setAnalysisStatus] = useState<AnalysisStatusType>('approved')

  const {
    isOpen: isOpenHandleLoadingRequestStatusModal,
    onOpen: onOpenHandleLoadingRequestStatusModal,
    onClose: onCloseHandleLoadingRequestStatusModal,
  } = useDisclosure()

  const {
    isOpen: isOpenUploadLoadingRequestReceiptsModal,
    onOpen: onOpenUploadLoadingRequestReceiptsModal,
    onClose: onCloseUploadLoadingRequestReceiptsModal,
  } = useDisclosure()

  const {
    isOpen: isOpenUpdateLoadingRequestModal,
    onOpen: onOpenUpdateLoadingRequestModal,
    onClose: onCloseUpdateLoadingRequestModal,
  } = useDisclosure()

  const {
    isOpen: isOpenDeleteLoadingRequestModal,
    onOpen: onOpenDeleteLoadingRequestModal,
    onClose: onCloseDeleteLoadingRequestModal,
  } = useDisclosure()

  const { data: loadingRequestData, isLoading, isError, error } = useGetLoadingRequest({ id })

  function handleStatus(status: AnalysisStatusType): void {
    setAnalysisStatus(status)
    onOpenHandleLoadingRequestStatusModal()
  }

  useEffect(() => {
    if (isError) {
      toastError({ toast, error })
    }
  }, [toast, isError, error])

  useEffect(() => {
    if (loadingRequestData && loadingRequestData.attachments && loadingRequestData.attachments.length > 0) {
      const genericAttachments = loadingRequestData.attachments
        .filter(
          attachment =>
            attachment.type === LoadingRequestAttachmentTypeEnum.GENERIC &&
            typeof attachment.attachment_file_url === 'string' &&
            attachment.attachment_file_url.trim() !== '',
        )
        .map(attachment => attachment.attachment_file_url!) as string[]

      const receiptAttachments = loadingRequestData.attachments
        .filter(
          attachment =>
            ['receipt', 'advance_receipt', 'balance_receipt'].includes(attachment.type) &&
            typeof attachment.attachment_file_url === 'string' &&
            attachment.attachment_file_url.trim() !== '',
        )
        .map(attachment => attachment.attachment_file_url!) as string[]

      setSplitAttachments({ genericAttachments, receiptAttachments })
    }
  }, [loadingRequestData])

  return (
    <Layout>
      {!isLoading ? (
        loadingRequestData ? (
          <>
            <Flex mb={2} alignItems="center" justifyContent="space-between">
              <Heading fontSize="2xl" display="flex" alignItems="center">
                <Text ml={2}>Frete #{loadingRequestData?.freight?.freight_number} - </Text>

                <Tooltip
                  label={loadingRequestStatusLegend(loadingRequestData?.status)}
                  hasArrow
                  placement="top"
                  arrowSize={15}
                >
                  <Badge
                    fontSize="sm"
                    px="2"
                    py="2"
                    colorScheme={getLoadingRequestColorByStatus(loadingRequestData.status)}
                    ml={2}
                  >
                    {loadingRequestData.formatted_status}
                  </Badge>
                </Tooltip>
              </Heading>

              <Flex>
                {loadingRequestData.status === LoadingRequestStatusEnum.AWAITING_ANALYSIS && (
                  <Ability module="spotHub" action="show-evaluate-loading-request">
                    <Box>
                      <Button
                        size="sm"
                        colorScheme="green"
                        onClick={() => {
                          handleStatus('approved')
                        }}
                      >
                        <Icon fontSize={16} as={IoCheckmark} mr={1} /> Aprovar
                      </Button>

                      <Button
                        size="sm"
                        colorScheme="red"
                        ml={2}
                        onClick={() => {
                          handleStatus('rejected')
                        }}
                      >
                        <Icon fontSize={20} as={IoCloseOutline} mr={1} /> Reprovar
                      </Button>
                    </Box>
                  </Ability>
                )}

                {loadingRequestData.status === LoadingRequestStatusEnum.FINISHED &&
                  loadingRequestData.cf_number && (
                    <Box>
                      <Badge fontSize="sm" px="2" py="2" colorScheme="blue">
                        Número CF - {loadingRequestData.cf_number}
                      </Badge>
                    </Box>
                  )}

                {loadingRequestData.status === LoadingRequestStatusEnum.AWAITING_PAYMENT && (
                  <Ability module="spotHub" action="show-upload-loading-request-receipt">
                    <Box>
                      <Button
                        size="sm"
                        colorScheme="yellow"
                        onClick={onOpenUploadLoadingRequestReceiptsModal}
                      >
                        <Icon fontSize={16} as={FiPaperclip} mr={1} /> Anexar comprovante
                      </Button>

                      <Button
                        size="sm"
                        colorScheme="cyan"
                        onClick={() => {
                          handleStatus('request_review')
                        }}
                        ml={2}
                      >
                        <Icon fontSize={16} as={RiEditLine} mr={1} /> Reprovar
                      </Button>
                    </Box>
                  </Ability>
                )}

                {loadingRequestData.status === LoadingRequestStatusEnum.PAID && (
                  <Box>
                    <Button
                      size="sm"
                      colorScheme="orange"
                      onClick={() => {
                        handleStatus('finish')
                      }}
                    >
                      <Icon fontSize={16} as={IoCheckmark} mr={1} /> Finalizar
                    </Button>
                  </Box>
                )}

                <Box ml={2} d="flex" alignItems="center">
                  <Menu placement="bottom-end" autoSelect={false}>
                    <MenuButton>
                      <Button size="sm">
                        <Icon fontSize={16} as={FiMoreVertical} />
                      </Button>
                    </MenuButton>

                    <MenuList zIndex={999}>
                      {loadingRequestData.status !== LoadingRequestStatusEnum.PAID &&
                        loadingRequestData.status !== LoadingRequestStatusEnum.FINISHED && (
                          <>
                            <MenuItem
                              icon={<Icon fontSize={20} as={RiEditLine} />}
                              onClick={() => {
                                onOpenUpdateLoadingRequestModal()
                              }}
                            >
                              Editar registro
                            </MenuItem>
                            <MenuDivider />
                          </>
                        )}

                      <MenuItem
                        icon={<Icon fontSize={20} as={FaRegTrashAlt} />}
                        onClick={() => {
                          onOpenDeleteLoadingRequestModal()
                        }}
                      >
                        Deletar registro
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Box>
              </Flex>
            </Flex>

            {loadingRequestData.reject_reason && (
              <Alert status="warning" variant="left-accent">
                <Box>
                  <AlertTitle mb={2}>Motivo de reprovação!</AlertTitle>
                  <AlertDescription>{loadingRequestData.reject_reason}</AlertDescription>
                </Box>
              </Alert>
            )}

            {loadingRequestData.observation && (
              <Alert status="info" variant="left-accent" mt={4}>
                <Box>
                  <AlertTitle mb={2}>Observação!</AlertTitle>
                  <AlertDescription>{loadingRequestData.observation}</AlertDescription>
                </Box>
              </Alert>
            )}

            {loadingRequestData.payment_observation && (
              <Alert status="info" variant="left-accent" mt={4}>
                <Box>
                  <AlertTitle mb={2}>Observação de pagamento!</AlertTitle>
                  <AlertDescription>{loadingRequestData.payment_observation}</AlertDescription>
                </Box>
              </Alert>
            )}

            <Box
              aria-label="short freight details"
              rounded="lg"
              bg={bg}
              borderRadius="8"
              shadow="md"
              p={3}
              mt={4}
            >
              <Heading
                size="md"
                fontWeight="normal"
                mb={4}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                Informações solicitação de carregamento
                <Tooltip label="Responsável - Data de criação" hasArrow placement="top" arrowSize={10}>
                  <Badge
                    fontSize="sm"
                    px="2"
                    py="2"
                    colorScheme="gray"
                    ml={2}
                    display="flex"
                    alignItems="center"
                  >
                    {loadingRequestData.creator?.name} - {loadingRequestData.created_at}
                  </Badge>
                </Tooltip>
              </Heading>

              <SimpleGrid minChildWidth="300px" spacing="4">
                <WrapItem>
                  <Text color="gray.400">Motivo:</Text>
                  <Text fontWeight="medium" ml="1">
                    {loadingRequestData?.reason}
                  </Text>
                </WrapItem>

                <WrapItem>
                  <Text color="gray.400">Tipo:</Text>
                  <Text fontWeight="medium" ml="1">
                    {loadingRequestData?.type}
                  </Text>
                </WrapItem>

                <WrapItem>
                  <Text color="gray.400">CTe: </Text>
                  <Text fontWeight="medium" ml="1">
                    {loadingRequestData?.cte ? loadingRequestData?.cte?.nct : 'Não informado'}
                  </Text>
                </WrapItem>

                <WrapItem>
                  <Text color="gray.400">Desconto Buonny: </Text>
                  <Text fontWeight="medium" ml="1">
                    {loadingRequestData.discount_buonny_formatted}
                  </Text>
                </WrapItem>

                <WrapItem>
                  <Text color="gray.400">Valor do custo total:</Text>
                  <Text fontWeight="medium" ml="1">
                    {loadingRequestData?.total_value_formatted}
                  </Text>
                </WrapItem>

                <WrapItem>
                  <Text color="gray.400">Valor bruto pagamento:</Text>
                  <Text fontWeight="medium" ml="1">
                    {loadingRequestData?.total_gross_formatted}
                  </Text>
                </WrapItem>

                <WrapItem>
                  <Text color="gray.400">Motorista: </Text>
                  <Text fontWeight="medium" ml="1">
                    {loadingRequestData.motorist?.name}
                  </Text>
                </WrapItem>

                <WrapItem>
                  <Text color="gray.400">Documento motorista: </Text>
                  <Text fontWeight="medium" ml="1">
                    {formatCPFAndCNPJ(loadingRequestData.motorist?.cpf)}
                  </Text>
                </WrapItem>

                <WrapItem>
                  <Text color="gray.400">Placa do veiculo: </Text>
                  <Text fontWeight="medium" ml="1">
                    {loadingRequestData.motorist_vehicle?.license_plate ?? 'Não informado'}
                  </Text>
                </WrapItem>

                {loadingRequestData.cf_number && (
                  <WrapItem>
                    <Text color="gray.400">Número CF: </Text>
                    <Badge fontSize="sm" px="1" colorScheme="green" ml={2}>
                      {loadingRequestData.cf_number}
                    </Badge>
                  </WrapItem>
                )}
              </SimpleGrid>

              <Divider mt={4} />

              <SimpleGrid minChildWidth="300px" spacing="4" mt={4}>
                <WrapItem>
                  <Text color="gray.400">Origem:</Text>
                  <Text fontWeight="medium" ml="1">
                    {loadingRequestData?.origin ? loadingRequestData?.origin : 'Não informado'}
                  </Text>
                </WrapItem>

                <WrapItem>
                  <Text color="gray.400">Destino:</Text>
                  <Text fontWeight="medium" ml="1">
                    {loadingRequestData?.destination ? loadingRequestData?.destination : 'Não informado'}
                  </Text>
                </WrapItem>
              </SimpleGrid>
            </Box>

            <Box
              aria-label="short freight details"
              rounded="lg"
              bg={bg}
              borderRadius="8"
              shadow="md"
              p={3}
              mt={4}
            >
              <Heading
                size="md"
                fontWeight="normal"
                mb={4}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                Informações gerais do frete
              </Heading>

              <SimpleGrid minChildWidth="300px" spacing="4">
                <WrapItem>
                  <Text color="gray.400">Número do frete:</Text>
                  <Tooltip label="Ir para página do frete" hasArrow>
                    <Text
                      as={Link}
                      to={`/freights/show/${loadingRequestData?.freight_id}`}
                      fontWeight="medium"
                      ml="1"
                    >
                      #{loadingRequestData?.freight?.freight_number}
                    </Text>
                  </Tooltip>
                </WrapItem>

                <WrapItem>
                  <Text color="gray.400">Status do frete:</Text>
                  <Badge px="2" py="1" colorScheme="gray" ml={2}>
                    {getStatus(loadingRequestData?.freight?.status)}
                  </Badge>
                </WrapItem>

                <WrapItem>
                  <Text color="gray.400">Cliente:</Text>
                  <Text fontWeight="medium" ml="1">
                    {loadingRequestData?.freight?.client?.name}
                  </Text>
                </WrapItem>

                <WrapItem>
                  <Text color="gray.400">Cliente CNPJ:</Text>
                  <Text fontWeight="medium" ml="1">
                    {formatCPFAndCNPJ(loadingRequestData?.freight?.client?.cnpj)}
                  </Text>
                </WrapItem>

                <WrapItem>
                  <Text color="gray.400">Km Percorrido:</Text>
                  <Text fontWeight="medium" ml="1">
                    {`${
                      Number(loadingRequestData.freight?.distance || 0) -
                      Number(loadingRequestData.freight?.distance_to_route || 0)
                    } Km`}
                  </Text>
                </WrapItem>

                <WrapItem>
                  <Text color="gray.400">Tipo de veículo:</Text>
                  <Text fontWeight="medium" ml="1">
                    {loadingRequestData.freight?.vehicle_categories
                      ?.map(category => category.name)
                      .join(' / ')}
                  </Text>
                </WrapItem>
              </SimpleGrid>
            </Box>

            <Box
              aria-label="short freight details"
              rounded="lg"
              bg={bg}
              borderRadius="8"
              shadow="md"
              p={3}
              mt={4}
            >
              <Heading
                size="md"
                fontWeight="normal"
                mb={4}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                Informações de pagamento
              </Heading>

              <SimpleGrid minChildWidth="300px" spacing="4">
                <WrapItem>
                  <Text color="gray.400">Nome favorecido:</Text>
                  <Text fontWeight="medium" ml="1">
                    {loadingRequestData?.beneficiary?.name}
                  </Text>
                </WrapItem>

                <WrapItem>
                  <Text color="gray.400">Documento favorecido:</Text>
                  <Text fontWeight="medium" ml="1">
                    {formatCPFAndCNPJ(
                      loadingRequestData?.beneficiary?.type === 'pf'
                        ? loadingRequestData?.beneficiary?.cpf
                        : loadingRequestData?.beneficiary?.cnpj,
                    )}
                  </Text>
                </WrapItem>

                <WrapItem>
                  <Text color="gray.400">E-Frete:</Text>
                  <Text fontWeight="medium" ml="1">
                    {loadingRequestData?.e_frete ? 'Sim' : 'Não'}
                  </Text>
                </WrapItem>

                {loadingRequestData.pix && (
                  <WrapItem>
                    <Text color="gray.400">Pix:</Text>
                    <Text fontWeight="medium" ml="1">
                      {loadingRequestData?.pix}
                    </Text>
                  </WrapItem>
                )}

                {loadingRequestData.account_type && (
                  <WrapItem>
                    <Text color="gray.400">Tipo de conta:</Text>
                    <Text fontWeight="medium" ml="1">
                      {formatAccountType(loadingRequestData?.account_type)}
                    </Text>
                  </WrapItem>
                )}

                {loadingRequestData.bank && (
                  <WrapItem>
                    <Text color="gray.400">Banco:</Text>
                    <Text fontWeight="medium" ml="1">
                      {loadingRequestData?.bank}
                    </Text>
                  </WrapItem>
                )}

                {loadingRequestData.agency && (
                  <WrapItem>
                    <Text color="gray.400">Agencia:</Text>
                    <Text fontWeight="medium" ml="1">
                      {loadingRequestData?.agency}
                    </Text>
                  </WrapItem>
                )}

                {loadingRequestData.account && (
                  <WrapItem>
                    <Text color="gray.400">Conta:</Text>
                    <Text fontWeight="medium" ml="1">
                      {loadingRequestData?.account}
                    </Text>
                  </WrapItem>
                )}

                {loadingRequestData.account_digit && (
                  <WrapItem>
                    <Text color="gray.400">Código banco:</Text>
                    <Text fontWeight="medium" ml="1">
                      {loadingRequestData?.account_digit}
                    </Text>
                  </WrapItem>
                )}
              </SimpleGrid>
            </Box>

            {loadingRequestData && loadingRequestData.freight && (
              <Box aria-label="short freight details" rounded="lg" bg={bg} borderRadius="8" shadow="md">
                <ListFreightContract freight={loadingRequestData.freight} bg={bg} />
              </Box>
            )}

            <Box
              aria-label="short freight details"
              rounded="lg"
              bg={bg}
              borderRadius="8"
              shadow="md"
              p={3}
              mt={4}
            >
              <Heading
                size="md"
                fontWeight="normal"
                mb={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                Arquivos Vinculados
              </Heading>

              <Tabs colorScheme="orange" w="full">
                <TabList w="full" overflowX="auto" overflowY="hidden">
                  {tabs.map(tab => (
                    <Tab
                      fontSize="sm"
                      key={tab}
                      _active={{ outline: 'none' }}
                      _focus={{ outline: 'none' }}
                      _hover={{ outline: 'none', bg: 'none' }}
                      whiteSpace="nowrap"
                    >
                      {tab}
                    </Tab>
                  ))}
                </TabList>

                <TabPanels>
                  {tabs.map(tab => (
                    <TabPanel key={tab} p="0" mt="4">
                      {tab === 'Gerais:' && (
                        <>
                          {splitAttachments &&
                          splitAttachments.genericAttachments &&
                          splitAttachments.genericAttachments.length > 0 ? (
                            <PreviewFiles images={splitAttachments.genericAttachments} displayMode="list" />
                          ) : (
                            <Text>Nenhum arquivo encontrado</Text>
                          )}
                        </>
                      )}
                      {tab === 'Recibos:' && (
                        <>
                          {splitAttachments &&
                          splitAttachments.receiptAttachments &&
                          splitAttachments.receiptAttachments.length > 0 ? (
                            <PreviewFiles images={splitAttachments.receiptAttachments} displayMode="list" />
                          ) : (
                            <Text>Nenhum recibo encontrado</Text>
                          )}
                        </>
                      )}
                    </TabPanel>
                  ))}
                </TabPanels>
              </Tabs>
            </Box>
          </>
        ) : isError ? (
          <Text textAlign="center">Erro ao carregar a solicitação de carregamento</Text>
        ) : (
          <Text textAlign="center">Nenhuma solicitação de carregamento encontrada</Text>
        )
      ) : (
        <Flex justifyContent="center" alignItems="center">
          <Spinner mr={2} />
          <Text>Carregando dados</Text>
        </Flex>
      )}

      {isOpenHandleLoadingRequestStatusModal && (
        <HandleLoadingRequestStatusModal
          isOpen={isOpenHandleLoadingRequestStatusModal}
          onClose={onCloseHandleLoadingRequestStatusModal}
          analysisStatus={analysisStatus}
        />
      )}

      {isOpenUploadLoadingRequestReceiptsModal && loadingRequestData && (
        <UploadLoadingRequestReceiptsModal
          isOpen={isOpenUploadLoadingRequestReceiptsModal}
          onClose={onCloseUploadLoadingRequestReceiptsModal}
          e_frete={loadingRequestData.e_frete}
        />
      )}

      {isOpenUpdateLoadingRequestModal && loadingRequestData && (
        <UpdateLoadingRequestModal
          isOpen={isOpenUpdateLoadingRequestModal}
          onClose={onCloseUpdateLoadingRequestModal}
          initial_value={loadingRequestData}
        />
      )}

      {isOpenDeleteLoadingRequestModal && (
        <DeleteLoadingRequestModal
          isOpen={isOpenDeleteLoadingRequestModal}
          onClose={onCloseDeleteLoadingRequestModal}
        />
      )}
    </Layout>
  )
}
