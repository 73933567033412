import { LoadingRequestStatusEnum } from '../types/EnumTypes'

export function loadingRequestStatusLegend(status?: LoadingRequestStatusEnum): string {
  if (status === LoadingRequestStatusEnum.AWAITING_ANALYSIS)
    return 'Aguardando responsável realizar a análise'
  if (status === LoadingRequestStatusEnum.AWAITING_PAYMENT)
    return 'Número de CF anexado, aguardando o responsável realizar o pagamento'
  if (status === LoadingRequestStatusEnum.PAID) return 'Responsável concluiu o pagamento'
  if (status === LoadingRequestStatusEnum.FINISHED) return 'Solicitação de carregamento finalizada'
  return 'Solicitação de carregamento reprovada pelo responsável'
}
